import { CommonModule } from '@angular/common';
import { Component, Input, signal } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SvgIconComponent } from 'angular-svg-icon';
import { NzMenuModule } from 'ng-zorro-antd/menu';
import { NzToolTipModule } from 'ng-zorro-antd/tooltip';
import { NgScrollbarModule } from 'ngx-scrollbar';

const menuList = [
  {
    name: 'Dashboard',
    link: ['/', 'expert-system'],
    type: 'All',
    exact: true,
    icon: 'assets/svgs/menu/dashboard.svg',
  },
  {
    name: 'Invoice Report',
    link: ['/', 'expert-system', 'invoice-reports'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/file-text.svg',
  },
  {
    name: 'Collection Report',
    link: ['/', 'expert-system', 'collection-reports'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/hard-drive.svg',
  },
  {
    name: 'Settlement Report',
    link: ['/', 'expert-system', 'settlement-reports'],
    type: 'All',
    exact: false,
    icon: 'assets/svgs/menu/report.svg',
  },
];

const menuList2 = [
  {
    name: 'Settings',
    link: `/expert-system/settings`,
    type: 'All',
    exact: false,
    icon: 'assets/svgs/settings.svg',
  },
  {
    name: 'Log Out',
    link: '/',
    type: 'Danger',
    exact: true,
    icon: 'assets/svgs/menu/log-out.svg',
  },
];

@Component({
  selector: 'app-expert-system-sidemenu',
  standalone: true,
  imports: [
    SvgIconComponent,
    NzToolTipModule,
    RouterModule,
    NgScrollbarModule,
    CommonModule,
    NzMenuModule,
  ],
  templateUrl: './expert-system-sidemenu.component.html',
  styleUrl: './expert-system-sidemenu.component.css',
})
export class ExpertSystemSidemenuComponent {
  @Input({ required: true }) isCollapsed = false;

  menuList = signal(menuList);
  menuList2 = signal(menuList2);
}
