import { afterNextRender, Component, inject, OnInit } from '@angular/core';
import { NavigationEnd, Router, RouterModule } from '@angular/router';
import { ToastCustomComponent } from './shared/widgets/toast/toast.component';
import { SpinnerComponent } from './shared/widgets/spinner/spinner.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import { environment } from '../environments/environment';
import { AngularQueryDevtools } from '@tanstack/angular-query-devtools-experimental';
import { CommonModule } from '@angular/common';
import { ScriptLoaderService } from './core/services/script-loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrl: './app.component.css',
  standalone: true,
  imports: [
    RouterModule,
    ToastCustomComponent,
    SpinnerComponent,
    NgxSpinnerModule,
    AngularQueryDevtools,
    CommonModule,
  ],
})
export class AppComponent implements OnInit {
  title = 'PayKaduna';
  isProduction = environment.production;

  constructor(private router: Router) {
    afterNextRender(() => {
      this.scriptLoader
        .loadScript(environment.remitaInlineUrl)
        .then(() => {
          // Access the global variable and function once the script has loaded
          console.log('Remita script loaded');
        })
        .catch((error) => {
          console.log('error :>> ', error);
          console.error('Error loading third-party script:', error);
        });

      this.router.events.subscribe((event) => {
        if (event instanceof NavigationEnd) {
          if (!!document.querySelector('nz-content')) {
            document.querySelector('nz-content').scrollTop = 0;
          }
        }
      });
    });
  }

  scriptLoader = inject(ScriptLoaderService);

  ngOnInit(): void {
    // Start all routes scroll position from top of page
  }
}
