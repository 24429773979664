import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class CountdownNoformattingService {
  displayTime: string = '';
  displaySeconds: number;
  private endTime!: number;
  private animationFrameId!: number;
  isCountdownFinished$: BehaviorSubject<boolean> = new BehaviorSubject(true);

  startCountdown(duration: number) {
    this.isCountdownFinished$.next(false);
    const now = performance.now();
    this.endTime = now + duration * 1000; // Convert seconds to milliseconds
    this.updateTimer(now);
  }

  watchCountDown() {
    return this.isCountdownFinished$.asObservable();
  }

  private updateTimer(currentTime: number) {
    const remainingTime = Math.max(0, this.endTime - currentTime); // Ensure non-negative
    this.displayTime = this.formatTime(remainingTime);
    this.secondsFormatting(remainingTime);

    if (remainingTime > 0) {
      this.animationFrameId = requestAnimationFrame(() =>
        this.updateTimer(performance.now())
      );
    } else {
      this.onCountdownComplete();
    }
  }

  private formatTime(milliseconds: number): string {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const minutes = Math.floor(totalSeconds / 60);
    const seconds = totalSeconds % 60;
    return `${this.padZero(minutes)}:${this.padZero(seconds)}`;
  }

  private secondsFormatting(milliseconds: number) {
    const totalSeconds = Math.floor(milliseconds / 1000);
    const seconds = totalSeconds % 60;
    this.displaySeconds = seconds;
  }

  private padZero(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  private cancelAnimation() {
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
  }

  private onCountdownComplete() {
    this.displayTime = '00:00';
    console.log('Countdown complete!');
    this.isCountdownFinished$.next(true);
    // You can emit an event or call a function here
  }
  constructor() {}
}
