import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { LocalService } from '../core/services/local.service';
import { AuthService } from '../core/services/auth.service';

export const parseUserDataFromLocalStore = () => {
  const localService = inject(LocalService);
  const userData = localService.getData('pay_kd_user');
  return {
    role: userData?.roles?.[0],
    isLoggedIn: !!userData,
  };
};

export const authUserGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;

  const router = inject(Router);
  const roles = [
    'Individual',
    'Agent',
    'Corporate',
    'State Agency',
    'Federal Agency',
  ];

  const isIndividualOrAgent = roles.includes(role);
  if (hasValidToken() === false) {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }

  if (!!hasValidToken()) {
    if (!isIndividualOrAgent) {
      router.navigate(['/'], { replaceUrl: true });
      return false;
    }

    return true;
  }
  return false;
};

export const authAgentGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const router = inject(Router);

  const isAgent = ['Agent', 'agent'].includes(role);

  if (isLoggedIn && !isAgent) {
    router.navigate(['/user/dashboard'], { replaceUrl: true });
    return false;
  }

  if (isAgent && isLoggedIn) {
    return true;
  } else {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }
};

export const authAdminGuard: CanActivateFn = (route, state) => {
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;
  const router = inject(Router);

  const isAdmin = ['Admin', 'Administrator', 'SuperAdmin'].includes(role);
  if (hasValidToken() === false) {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }

  if (!!hasValidToken()) {
    if (!isAdmin) {
      router.navigate(['/'], { replaceUrl: true });
      return false;
    }
    return true;
  }
  //   router.navigate([]);
  return false;
};

export const authSuperAdminGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;

  const isSuperAdmin = role === 'SuperAdmin';

  if (hasValidToken() === false) {
    router.navigate(['/']);
    return false;
  }
  if (!!hasValidToken()) {
    if (!isSuperAdmin) {
      router.navigate(['/']);
      return false;
    }
    return true;
  }
  return false;
};

export const govGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;

  const isGovernor = role === 'Governor';

  //   return true;
  if (hasValidToken() === false) {
    router.navigate(['/']);
    return false;
  }
  if (hasValidToken() === true) {
    if (!isGovernor) {
      router.navigate(['/']);
      return false;
    }
    return true;
  }
  return false;
};

export const expertSystemGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;

  const isExpertSystem = role === 'Expert System';

  if (hasValidToken() === false) {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }
  if (!!hasValidToken()) {
    if (!isExpertSystem) {
      router.navigate(['/']);
      return false;
    }
    return true;
  }
  return false;
};

export const mdaGuard: CanActivateFn = (route, state) => {
  const router = inject(Router);
  const { role, isLoggedIn } = parseUserDataFromLocalStore();
  const authService = inject(AuthService);
  const { hasValidToken } = authService;

  const isExpertSystem = role === 'MDA User';

  if (hasValidToken() === false) {
    router.navigate(['/'], { replaceUrl: true });
    return false;
  }
  if (!!hasValidToken()) {
    if (!isExpertSystem) {
      router.navigate(['/']);
      return false;
    }
    return true;
  }
  return false;
};
