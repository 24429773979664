<nz-modal
  [(nzVisible)]="modalState"
  [nzFooter]="null"
  [nzClosable]="false"
  (nzOnCancel)="onCancel()"
  [nzTitle]="null"
  [nzMaskClosable]="false"
>
  <div *nzModalContent>
    <div
      [ngClass]="{
        'h-[445px] max-h-[445px] overflow-auto flex flex-col': true
      }"
    >
      <div
        [ngClass]="{
          'flex items-center w-full max-w-[438px] mx-auto': true
        }"
      >
        <img
          [ngSrc]="'assets/images/primeguage_logo_payment_redirect_page.png'"
          alt="primeguage-logo"
          priority
          width="62"
          height="52.5"
          [ngClass]="{ 'h-[52.5px] w-[62px]': true }"
        />
        <div class="flex-1 h-[3px] bg-slate-300">
          <div
            [ngClass]="{
                'w-[0%] bg-[#178CA6] h-full': true,
                progress_animate: !isSuccess(),
                progress_complete: isSuccess(),
              }"
          ></div>
        </div>
        <img
          [ngSrc]="'assets/images/remita_logo_payment_redirect_page.png'"
          alt="remita-logo"
          priority
          width="40"
          height="45"
          [ngClass]="{ 'h-[45px] w-10': true }"
        />
      </div>
      <h4
        [ngClass]="{
          'mt-6 text-center font-bold text-[20px] leading-[38px]': true
        }"
        *ngIf="!isSuccess()"
      >
        Payment Confirmation Pending
      </h4>
      <h4
        [ngClass]="{
          'mt-6 text-center font-bold text-[20px] leading-[38px]': true
        }"
        *ngIf="isSuccess()"
      >
        Payment Confirmed
      </h4>
      <div [ngClass]="{ 'text-center text-[#717272] pt-5 flex-1': true }">
        @if (!isSuccess()) {
        <p>
          We're waiting for payment confirmation.<br />
          Thank you for your patience.
        </p>

        }@else {
        <div [ngClass]="{ 'flex flex-col gap-5 items-center': true }">
          <div [ngClass]="{ '': true }">
            <p>Thank you for your patience.</p>

            <p>You will be redirected shortly.</p>
          </div>
          <div>
            <nz-progress
              [nzPercent]="
                (countDownNoFormattingService.displaySeconds / 10) * 100
              "
              nzType="circle"
              [nzFormat]="formatOne"
              [nzStrokeColor]="{ '0%': '#178CA6', '100%': '#178CA6' }"
              [nzWidth]="100"
            ></nz-progress>
          </div>
        </div>
        }
      </div>
    </div>
  </div>
</nz-modal>
