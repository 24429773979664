import { inject, Injectable } from '@angular/core';
import * as signalR from '@microsoft/signalr';
import { environment } from '../../../environments/environment';
import { ObjectLiteral } from '../models/ObjectLiteral';
import { AuthService } from './auth.service';
import { BehaviorSubject } from 'rxjs';

// const options = {
//   accessTokenFactory: () => yourJwtToken,
// };

@Injectable({
  providedIn: 'root',
})
export class SignalRService {
  private hubConnection!: signalR.HubConnection;
  private isConnected$: BehaviorSubject<boolean> = new BehaviorSubject(false);
  authService = inject(AuthService);

  public startConnection() {
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.socket_url, {
        accessTokenFactory: () => this.authService.userData()?.token,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');

        this.isConnected$.next(true);
      })
      .catch((err) => console.log('Error while starting connection: ' + err));
  }
  public startConnectionAnonymous() {
    const uuid = self.crypto.randomUUID();
    this.hubConnection = new signalR.HubConnectionBuilder()
      .withUrl(environment.socket_url_anonymous, {
        accessTokenFactory: () => uuid,
      })
      .build();

    this.hubConnection
      .start()
      .then(() => {
        console.log('Connection started');

        this.isConnected$.next(true);
      })
      .catch((err) => console.log('Error while starting connection: ' + err));
  }

  watchConnection() {
    return this.isConnected$.asObservable();
  }

  public addMessageListener(
    triggerMessage,
    callback: (data: ObjectLiteral) => void
  ) {
    this.hubConnection.on(triggerMessage, callback);
  }

  public sendMessage(
    triggerString: string,
    // user: string,
    message: ObjectLiteral
  ) {
    this.hubConnection
      ?.invoke(triggerString, message)
      .catch((err) => console.error(err));
  }

  constructor() {}
}
