import { createAction, props } from '@ngrx/store';
import { CollectionReportReducerInitialState } from '../reducers/admin-collection-report.reducer';

const prefix = '[Admin-collection-report]';

export const setStateAction = createAction(
  `${prefix} set state`,
  props<Partial<CollectionReportReducerInitialState>>()
);

export const setQueryAction = createAction(
  `${prefix} set query`,
  props<
    Partial<{
      minDate: string;
      maxDate: string;
      pgId?: string;
      pcId?: string;
      tpcId?: string;
      mdaId?: string;
      engineCodeId?: string;
    }>
  >()
);

export const resetQueryAction = createAction(
  `${prefix} reset query`,
  props<any>()
);
