import { CommonModule, Location, NgOptimizedImage } from '@angular/common';
import { Component, inject, signal } from '@angular/core';
import { NzModalModule } from 'ng-zorro-antd/modal';
import { SignalRService } from '../../../core/services/signal-r.service';
import { injectQueryParams } from 'ngxtension/inject-query-params';
import { AuthService } from '../../../core/services/auth.service';
import { ObjectLiteral } from '../../../core/models/ObjectLiteral';
import { NzProgressModule } from 'ng-zorro-antd/progress';
import { CountdownNoformattingService } from '../../../core/services/countdown-noformatting.service';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-payment-redirect',
  standalone: true,
  imports: [NzModalModule, CommonModule, NgOptimizedImage, NzProgressModule],
  templateUrl: './payment-redirect.component.html',
  styleUrl: './payment-redirect.component.css',
})
export class PaymentRedirectComponent {
  billRef = injectQueryParams('billRef');
  isAnonymous = injectQueryParams('isAnonymous');
  isMdaUser = injectQueryParams('isMdaUser');
  signalRService = inject(SignalRService);
  signalSubscription: Subscription;
  countDownSubscription: Subscription;
  router = inject(Router);
  location = inject(Location);
  isConnected = signal<boolean>(false);
  socketResponse = signal<ObjectLiteral | null>(null);
  //   countDownService = inject(CountdownService);
  countDownNoFormattingService = inject(CountdownNoformattingService);

  authService = inject(AuthService);
  isSuccess = signal<boolean>(false);
  formatOne = (percent: number): string => {
    const val = percent / 10;
    return `${val} ${val <= 1 ? 'sec' : 'secs'}`;
  };

  modalState = true;

  onCancel(): void {
    this.modalState = false;
  }

  get parseInteger() {
    return parseInt;
  }

  get getAnonymityStatus() {
    return this.router.url.includes('/payment-redirect-anonymous');
  }

  ngOnInit(): void {
    this.countDownSubscription = this.countDownNoFormattingService
      .watchCountDown()
      .subscribe((data: boolean) => {
        if (data && !!this.isSuccess()) {
          this.router.navigate(['/payment_summary'], {
            queryParams: {
              ref: this.billRef(),
              ...(this.isAnonymous() ? { isAnonymous: true } : {}),
              ...(this.isMdaUser() ? { isMdaUser: true } : {}),
            },
          });
          return;
        }
        if (
          data &&
          !this.isSuccess() &&
          !!this.isConnected() &&
          !!this.socketResponse()
        ) {
          const userData = this.authService.userData();

          this.signalRService.sendMessage('PaymentNotificationRequest', {
            userId: userData?.userId,
            billReference: this.billRef(),
          });

          this.countDownNoFormattingService.startCountdown(20);
        }
      });
    const isAnonymous = this.router.url.includes('/payment-redirect-anonymous');

    isAnonymous
      ? this.signalRService.startConnectionAnonymous()
      : this.signalRService.startConnection();

    //PaymentNotificationResponse
    this.signalRService.addMessageListener(
      'PaymentNotificationResponse',
      (data: ObjectLiteral) => {
        this.socketResponse.set(data);
        if (![400, 200].includes(data.statusCode)) {
          this.isSuccess.set(false);
          return;
        }
        this.countDownNoFormattingService.startCountdown(10);
        this.isSuccess.set(true);
        return;
      }
    );

    this.signalSubscription = this.signalRService
      .watchConnection()
      .subscribe((data) => {
        this.isConnected.set(data);

        if (!!data && !!this.billRef()) {
          const userData = this.authService.userData();

          this.signalRService.sendMessage('PaymentNotificationRequest', {
            userId: userData?.userId,
            billReference: this.billRef(),
          });
        }
      });
  }

  ngOnDestroy(): void {
    //Called once, before the instance is destroyed.
    //Add 'implements OnDestroy' to the class.
    if (this.countDownSubscription) {
      this.countDownSubscription.unsubscribe();
    }
    if (this.signalSubscription) {
      this.signalSubscription.unsubscribe();
    }
  }
}

//'1354770000022'
